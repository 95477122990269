var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0"},[_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.savePasswordPolicies()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"outlined":"","dense":"","label":"Días de caducidad","rules":[_vm.rules.minMaxNumber(_vm.diasCaducidad, 0, 365)],"hint":"Días de caducidad de contraseña","persistent-hint":"","suffix":"días"},model:{value:(_vm.diasCaducidad),callback:function ($$v) {_vm.diasCaducidad=$$v},expression:"diasCaducidad"}})],1),_c('v-col',{staticClass:"pa-0 mt-5",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" En cero no caduca y de 1 a 365 si caduca. ")])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","dense":"","rules":[_vm.rules.minMaxNumber(_vm.diasAvisoPrevio, 0, 99)],"hint":"Días de aviso previo de caducidad de contraseña","persistent-hint":"","label":"Días de aviso previo","suffix":"días"},model:{value:(_vm.diasAvisoPrevio),callback:function ($$v) {_vm.diasAvisoPrevio=$$v},expression:"diasAvisoPrevio"}})],1),_c('v-col',{staticClass:"pa-0 mt-5",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" En cero no da aviso y de 1 a 99 si da aviso. ")])])],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","rules":[
                        _vm.rules.minMaxNumber(_vm.cantContraseñasAnteriores, 1, 10)
                      ],"dense":"","hint":"Cantidad de contraseñas anteriores que no se pueden utilizar","persistent-hint":"","label":"Cantidad de contraseñas anteriores","suffix":"contraseña/s"},model:{value:(_vm.cantContraseñasAnteriores),callback:function ($$v) {_vm.cantContraseñasAnteriores=$$v},expression:"cantContraseñasAnteriores"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","dense":"","rules":[_vm.rules.minMaxNumber(_vm.longitudMinima, 1, 25)],"label":"Longitud mínima","suffix":"caracteres"},model:{value:(_vm.longitudMinima),callback:function ($$v) {_vm.longitudMinima=$$v},expression:"longitudMinima"}})],1),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"3","md":"3"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_vm._v(" Exige letras y números "),_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","outlined":""},model:{value:(_vm.exigeLetrasNumeros),callback:function ($$v) {_vm.exigeLetrasNumeros=$$v},expression:"exigeLetrasNumeros"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"color":"primary","form":"form","type":"submit","loading":_vm.isLoading}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }