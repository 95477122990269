<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-row>
          <v-col cols="12">
            <v-card class="pa-0">
              <v-card-text class="py-0">
                <v-form
                  v-model="isFormValid"
                  ref="form"
                  form="form"
                  id="form"
                  @submit.prevent="savePasswordPolicies()"
                >
                  <v-row>
                    <v-col cols="2" md="2" class="pb-0">
                      <v-text-field
                        v-model="diasCaducidad"
                        outlined
                        dense
                        label="Días de caducidad"
                        v-mask="'###'"
                        :rules="[rules.minMaxNumber(diasCaducidad, 0, 365)]"
                        hint="Días de caducidad de contraseña"
                        persistent-hint
                        suffix="días"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="1" class="pa-0 mt-5">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="primary">
                            {{ infoIcon }}
                          </v-icon>
                        </template>
                        <span>
                          En cero no caduca y de 1 a 365 si caduca.
                        </span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" md="3" class="pb-0">
                      <v-text-field
                        v-model="diasAvisoPrevio"
                        outlined
                        dense
                        v-mask="'##'"
                        :rules="[rules.minMaxNumber(diasAvisoPrevio, 0, 99)]"
                        hint="Días de aviso previo de caducidad de contraseña"
                        persistent-hint
                        label="Días de aviso previo"
                        suffix="días"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="1" class="pa-0 mt-5">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="primary">
                            {{ infoIcon }}
                          </v-icon>
                        </template>
                        <span>
                          En cero no da aviso y de 1 a 99 si da aviso.
                        </span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" md="3" class="pb-0">
                      <v-text-field
                        v-model="cantContraseñasAnteriores"
                        outlined
                        v-mask="'##'"
                        :rules="[
                          rules.minMaxNumber(cantContraseñasAnteriores, 1, 10)
                        ]"
                        dense
                        hint="Cantidad de contraseñas anteriores que no se pueden utilizar"
                        persistent-hint
                        label="Cantidad de contraseñas anteriores"
                        suffix="contraseña/s"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="2" md="2" class="pb-0">
                      <v-text-field
                        v-model="longitudMinima"
                        outlined
                        dense
                        v-mask="'##'"
                        :rules="[rules.minMaxNumber(longitudMinima, 1, 25)]"
                        label="Longitud mínima"
                        suffix="caracteres"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="3" md="3" class="pt-2">
                      <v-subheader class="py-0 px-0">
                        Exige letras y números
                        <v-switch
                          class="ml-4"
                          v-model="exigeLetrasNumeros"
                          dense
                          outlined
                        ></v-switch>
                      </v-subheader>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!isFormValid"
                  color="primary"
                  form="form"
                  type="submit"
                  :loading="isLoading"
                >
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";

export default {
  name: "PoliticaContraseñas",
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  data: () => ({
    routeToGo: "ConfiguracionAdmSistema",
    rules: rules,
    title: enums.titles.POLITICA_CONTRASEÑAS,
    optionCode: enums.webSiteOptions.POLITICA_CONTRASEÑAS,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    isFormValid: false,
    diasCaducidad: 0,
    diasAvisoPrevio: 0,
    cantContraseñasAnteriores: 1,
    longitudMinima: 1,
    exigeLetrasNumeros: false,
    isLoading: false,
    infoIcon: enums.icons.SNB_INFO
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPasswordPolicies();
  },
  methods: {
    ...mapActions({
      getPoliticaContraseñas: "AdministracionSistema/getPoliticaContraseñas",
      postPoliticaContraseñas: "AdministracionSistema/postPoliticaContraseñas",
      setAlert: "user/setAlert"
    }),
    async setPasswordPolicies() {
      const response = await this.getPoliticaContraseñas();
      this.diasCaducidad = response.diasCaducidad;
      this.diasAvisoPrevio = response.diasAvisoPrevio;
      this.cantContraseñasAnteriores = response.cantContraseñasAnteriores;
      this.longitudMinima = response.longitudMinima;
      this.exigeLetrasNumeros = response.exigeLetrasNumeros;
    },
    async savePasswordPolicies() {
      try {
        this.isLoading = true;
        const data = {
          diasCaducidad: this.diasCaducidad,
          diasAvisoPrevio: this.diasAvisoPrevio,
          cantContraseñasAnteriores: this.cantContraseñasAnteriores,
          longitudMinima: this.longitudMinima,
          exigeLetrasNumeros: this.exigeLetrasNumeros
        };
        const response = await this.postPoliticaContraseñas(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Actualizado con éxito." });
          this.setPasswordPolicies();
        }
      } catch (error) {}
      this.isLoading = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
